import { graphql, PageProps } from "gatsby"
import "twin.macro"
import { Layout, SEO } from "../components"

const PageTemplate = ({ data }: PageTemplateProps) => {
  const title = data.markdownRemark.frontmatter.title
  const html = data.markdownRemark.html

  return (
    <Layout>
      <SEO title={title} />
      <div tw="container py-16">
        <section tw="prose prose-green mx-auto">
          <h1>{title}</h1>
          <div tw="mt-4" dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`

type PageTemplateProps = PageProps<{
  markdownRemark: {
    html: string
    frontmatter: {
      title: string
    }
  }
}>

export default PageTemplate
